@use '../node_modules/bulma/bulma.sass';

.overview,
.outcomes {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  padding: 100px;
}

.text-centered {
  text-align: center;
}

.container{
  margin-top: 100px;
}

#root {
  // padding-left: 180px;
}

.panel-block {
  display: block;
  background-color: #ffffff;
}

.overflow-scroll {
  overflow: auto;
  word-break: break-all;
}

.center {
  display: flex;
  justify-content: center;
}

.fixed-navbar-padding {
  padding-top: 3.25rem;
}

.table-hover-inspect-cursor > tbody > tr:not(:first-child):hover {
  cursor: pointer;
}

.inspect-cell {
  cursor: pointer;
  color: #4C63C8;
}

body {
  font-family: 'Lato';
}

.table-header-row {
  background-color: #4C63C8;
}

.table-header-row th {
  color: #fff !important;
}

.table-header-row:hover {
  background-color: #4C63C8 !important;
}

.centered-paragraph-heading {
  text-align: center;
  margin-top: 10px;
}

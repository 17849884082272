  .nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
  }
  
  .nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 4px;
  }
  
  .nav-text a:hover {
    background-color: #1a83ff;
  }

  .navbar-colors {
    background-color: #1b4b81 !important;
    color: rgb(237, 237, 237) !important;
  }
  
  